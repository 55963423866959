import React from 'react'
import { Link } from 'gatsby'
import styles from '../styles/pill.module.css'

const Pill = ({link, active, label, disabled = false}) => {
    return link? (
        <Link to={link} className={`${styles.pill} ${disabled? styles.disabled : active? styles.active : ''}`} data-tooltip={label} data-tooltip-only-if-overflow={true}>{label}</Link>
    ) : (
        <div className={`${styles.pill} ${disabled? styles.disabled : active? styles.active : ''}`} data-tooltip={label} data-tooltip-only-if-overflow={true}>{label}</div>
    )
}


export default Pill
