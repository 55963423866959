import React, { useState } from 'react'
import { Link } from 'gatsby'
import Pill from './pill'
import styles from '../styles/PublicationList.module.css'
import PlusIcon from '../assets/plus.svg'
import MinusIcon from '../assets/minus.svg'
import NextIcon from '../assets/next.svg'

const PublicationList = ({ publications, max, readMore }) => {

    const [showRelated, setShowRelated] = useState(false)

    const toggleShowRelated = () => {
        setShowRelated(!showRelated)
    }

    return publications?.length > 0? (
        <div className={styles.publications}>
            <button className={styles.header} onClick={toggleShowRelated}>
                {
                    showRelated? (
                        <>
                            <MinusIcon/>
                            <span>{`Hide related publications`}</span>
                        </>
                    ) : (
                        <>
                            <PlusIcon/>
                            <span>{`Show related publications`}</span>
                        </>
                    )
                }
            </button>
            {
                showRelated && publications.filter((o) => !o.data.HideFromNetwork ).slice(0, max).map((publication, i) => (
                    <a key={i} href={publication.data.Source} target='_blank' rel='noreferrer' className={styles.publication}>
                        <NextIcon/>
                        <div className={styles.title}>
                            <span>{publication.data.Title}</span>
                            {
                              ( publication.data.Journal && (
                                <Pill label={publication.data.Journal} disabled={true}/>
                              ) )  ||  ( publication.data.Source_type && (
                                    <Pill label={publication.data.Source_type} disabled={true}/>
                                ) )
                            }
                            {
                                publication.data.Author && (
                                    <div className={styles.author}>by {publication.data.Author}</div>
                                )
                            }
                        </div>
                    </a>
                ))
            }
            {
                readMore && showRelated && publications.length > max && (
                    <Link to={readMore} className={styles.readMore}>{`Read more...`}</Link>
                )
            }
        </div>
    ) : null
}

export default PublicationList
