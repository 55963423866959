import React from 'react'
import styles from '../styles/search.module.css'
import SearchIcon from '../assets/search.svg'
import CloseIcon from '../assets/close.svg'

const Search = ({className, value, handleSearch}) => (
    <div className={`${styles.search} ${className}`}>
        <SearchIcon className={styles.searchIcon}></SearchIcon>
        <input type='text' placeholder='Search' onChange={e => handleSearch(e.target.value)} onKeyDown={e => {if(e.key === 'Escape'){handleSearch('')}}} value={value}></input>
        <CloseIcon className={styles.clearIcon} style={{visibility: value.length > 0? 'visible' : 'hidden'}} onClick={() => handleSearch('')}/>
    </div>
)

export default Search