import React from 'react'
import Pill from '../components/pill'
import Search from '../components/search'
import styles from '../styles/filters.module.css'

const Filters = ({ path, tags, currentTag, search, handleSearch, handleClick }) => (
    <div className={styles.filters} onClick={handleClick} role='button' tabIndex='0' onKeyDown={() => {}}>
        <div className={styles.tags}>
            <Pill link={path} active={currentTag === undefined} label={'All'}/>
            {
                tags.map((tag, i) => <Pill key={i} link={`${path}?tag=${tag}`} active={currentTag === tag} label={tag}/>)
            }
        </div>
        <Search className={styles.search} value={search} handleSearch={handleSearch}/>
    </div>
)

export default Filters