import React, { useState, useEffect } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Filters from '../components/filters'
import Layout from '../components/layout'
import PromptButton from '../components/promptButton'
import Message from '../components/message'
//import MemberCard from '../components/memberCard'
import styles from '../styles/network.module.css'
import stylesCard from '../styles/memberCard.module.css'
import IconNetwork from '../assets/icon-network.svg'
import PublicationList from "../components/publicationList"
import Pill from "../components/pill"

const Awardees = ({ data }) => {

    const {allAirtableAwardees:awardee} = data
    const [currentTag] = useQueryParam('tag', StringParam)
    const [scroll, setScroll] = useState(0)
    const [search, setSearch] = useState('')
    const tags = [...new Set(awardee.nodes.map(resource => resource.data.category ?? []).flat())].sort()

    const filteredNetwork = awardee.nodes
        .filter(member => currentTag === undefined || member.data.category?.includes(currentTag))
        .filter(member => search === '' || member.data.category.toLowerCase().includes(search.toLocaleLowerCase()) || (member.data.Name?.[0].data.Name ?? '').toLowerCase().includes(search.toLocaleLowerCase()))

    const awardeePublications = (awardee) => {
      var pubs = []
  //    console.log("awardee:" + JSON.stringify(awardee))
      const wPubs =  awardee.People?.filter( (p) =>  {
    //   console.log("awardee person:" + JSON.stringify(p))
    //    console.log("awardee person pub count:" + (p.data?.RadXPublication?.length > 0 ) )
        return p.data?.RadXPublication
      } ).map((p) => p.data.RadXPublication )
  //    console.log("filtered pubs:" +JSON.stringify(wPubs))
  //    console.log("awardee pubs length:" + wPubs?.length)
      if (wPubs?.length > 0) {
  //      console.log("awardee pubs loop:" + wPubs?.length)
        wPubs.forEach((a) => {
  //        console.log("awardee publication:" + JSON.stringify(a))
         for (let i = 0; i < a.length; i++) {
            pubs.push(a[i]);
          }

        })
      }
 //     console.log(`awardee: ${awardee.Name}  ${pubs.length}`)
 //     console.log(`awardee pub list:  ${JSON.stringify(pubs)}`)
      return pubs
      // return {
      //   RadXPublication:pubs
      // }
    }

    useEffect(() => {
        window.scrollTo(0, scroll)
	})

    return (
		<Layout>
            <div className={styles.header}>
                <h1>Radx Rad Publications</h1>
                {/*<PromptButton primary={true} handleClick={handleMaterialRequest}>Contact experts</PromptButton>*/}
            </div>
            <Filters path={`/project`} tags={tags} currentTag={currentTag} search={search} handleClick={() => setScroll(window.scrollY)} handleSearch={value => setSearch(value)}/>
            {
                filteredNetwork.length > 0? (
                    <div className={styles.network}>
                        {
                            filteredNetwork.map((award, i) => {
                                const {data} = award
                                const awardSlug = data.Slug
                                const awardPublications = awardeePublications(data)
                                return (
                                  <div className={stylesCard.memberCard} key={i}>
                                    <div className={stylesCard.content}>
                                      <Link to={`/project/${awardSlug}`} className={styles.member}>
                                      <div>{data.Name}</div>
                                      </Link>
                                      <Pill key={i} link={`/project?expertise=${data.category}`} label={data.category}/>
                                      { awardPublications &&
              (<PublicationList publications={awardPublications} max={3} readMore={`/project/${data.Name}`}>{data.Title}</PublicationList>)


                                      }
                                    </div>
                                  </div>
                                    // <MemberCard key={data.Slug} name={data.Display_name} picture={data.Picture?.[0].url} institution={data.Institution?.[0].data.Name} role={data.Role} linkedIn={data.LinkedIn} website={data.Website} expertise={data.Expertise} currentTag={currentTag} publications={data.Publications ?? undefined} slug={data.Slug}/>
                                )
                            })
                        }
                    </div>
                ) : (
                    <Message Icon={IconNetwork} content={'Award not found'}/>
                )
            }
		</Layout>
	)
}

export const query = graphql`
    query  {
  allAirtableAwardees {
    nodes {
      data {
        People {
          ... on AirtablePeople {
            id
            data {
              display_name
              RadXPublication {
                data {
                  Abstract
                  Author
                  Date
                  HideFromNetwork
                  Journal
                  Name
                  Last_Modified
                  Topics
                  keywords
                  Title
                  Source
                  Source_type
                }
              }
            }
          }
        }
        Name
          Slug
        NIH_Project_Reporter
        category
        project_title
        pi_organization  {
            ... on  AirtableInstitutions {
                id
                data {
                    Name
                }
            }
        }
        grant_number
      }
    }
  }
}
`

export default Awardees
